<template>
    <b-overlay :show="isLoadingData">
        <b-card>
            <div class="head-btn">
                <b-button
                    v-if="can('fivesclub_web_owners_show_owners_general_documents_add_button')"
                    :to="{ name: 'create-general-document'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>

            <b-table
                ref="refStaffListTable"
                :items="ownersDocuments"
                :fields="tableColumns"
                sort-by="priority"
                show-empty
                responsive
                sticky-header="400px"
                empty-text="No hay registros"
                class="position-relative mb-0"
            >
                <template #cell(status)="row">
                    <span class="text-nowrap">
                        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                            {{ row.item.status ? "Activo" : "Desactivado" }}
                        </b-badge>
                    </span>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center">
                        <b-button size="sm" :to="{ name: 'edit-general-document', params: { id: row.item.id } }" variant="primary" v-if="can('fivesclub_web_owners_show_owners_general_documents_edit_button')">
                            <feather-icon icon="Edit2Icon" size="12" />
                        </b-button>
                        <b-button size="sm" @click="deleteDocument(row.item.id)"  variant="danger" class="ml-1" v-if="can('fivesclub_web_owners_show_owners_general_documents_delete_button')">
                            <feather-icon icon="TrashIcon" size="12" />
                        </b-button>
                    </div>
                </template>
            </b-table>

        </b-card>
    </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"

export default {
	mixins: [acl],
    directives: { Ripple, },
    async created(){
        await this.init();
    },
    data(){
        return {
            isLoadingData: false,
            tableColumns: [
                { key: 'name', label: 'Nombre', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'typename', label: 'Tipo', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'documentName', label: 'Archivo', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'status', label: 'Estado', class: 'text-center'},
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ]
        }
    } ,
    computed: {
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
        ...mapState("fivesClubCatalogs", ["ownersDocuments"]),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["fetchOwnersGeneralDocs", "deleteOwnersGeneralDocument"]),
        ...mapMutations('fivesClubCatalogs', ["setOwnersGeneralDocs"]),

        async init(){
            this.isLoadingData = true
            const documents = await this.fetchOwnersGeneralDocs({all: true})
            this.setOwnersGeneralDocs(documents)

            this.isLoadingData = false
        },
        async deleteDocument(id){
            const response = await this.deleteOwnersGeneralDocument({id, deleteDocument:true})
            if (response) await this.init()
        }
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}

.p-custom-staff{
padding: 0.35rem;
}

</style>
